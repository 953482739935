import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "AppHome",
    component: () => import("../components/AppHome.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("../components/NotFound.vue"),
  },
  {
    path: "/ComingSoon",
    name: "ComingSoon",
    component: () => import("../components/ComingSoon.vue"),
  },
  {
    path: "/News/:type/:BrnId",
    name: "News-With-Type-And_BrnId",
    component: () => import("../components/News.vue"),
  },
  {
    path: "/NewsDetails/:type/:id/:BrnId",
    name: "NewsDetails-with-id-And-Type-And-BrnId",
    component: () => import("../components/News-Details.vue"),
  },
  {
    path: "/ContactUs/",
    name: "ContactUs",
    component: () => import("../components/ContactUs.vue"),
  },
  {
    path: "/Events/:type",
    name: "Events-With-Type",
    component: () => import("../components/Events.vue"),
  },
  {
    path: "/Event-Details/:type/:id",
    name: "Event-with-id-And-Type",
    component: () => import("../components/Event-Details.vue"),
  },
  {
    path: "/Department/:type/:BrnId",
    name: "Department-with-BrnId-And-Type",
    component: () => import("../components/Department.vue"),
  },
  {
    path: "/Department-Details/:type/:BrnId/:id",
    name: "DepartmentDetails-with-BrnId-And-Type-And-id",
    component: () => import("../components/Department-details.vue"),
  },
  {
    path: "/UnivDeptDetails/:BrnId/:id",
    name: "UnivDeptDetails-with-BrnId-And-id",
    component: () => import("../components/Univ-Dept-Details.vue"),
  },
  {
    path: "/UnivDeanDetails/:BrnId/:id",
    name: "UnivDeanDetails-with-BrnId-And-id",
    component: () => import("../components/Univ-Dean-Details.vue"),
  },

  {
    path: "/issue/:type/:BrnId",
    name: "issue-with-BrnId-And-Type",
    component: () => import("../components/issue.vue"),
  },
  {
    path: "/issue-Details/:type/:BrnId/:id",
    name: "issue-with-BrnId-And-Type-And-id",
    component: () => import("../components/issue-Details.vue"),
  },

  {
    path: "/UnivDetails/:type/:id",
    name: "Univ-with-id-And-Type",
    component: () => import("../components/Univ-Details.vue"),
  },
  {
    path: "/ActivityDetails/:id",
    name: "Activity-with-id",
    component: () => import("../components/Activity-Details.vue"),
  },

  {
    path: "/Univ/:type",
    name: "Univ-with-Type",
    component: () => import("../components/Univ.vue"),
  },
  {
    path: "/Faculties/:id",
    name: "Faculties-with-id",
    component: () => import("../components/Faculties.vue"),
  },
  {
    path: "/FaculityMembers/:BrnId",
    name: "FaculityMembers",
    component: () => import("../components/Faculity-Members.vue"),
  },
  {
    path: "/FaculityMembersDetails/:BrnId/:id",
    name: "FaculityMembersDetails",
    component: () => import("../components/Faculity-Members-Details.vue"),
  },
  {
    path: "/Deans/:id",
    name: "Deans-with-id",
    component: () => import("../components/Deans.vue"),
  },
  {
    path: "/research/:id",
    name: "research-with-id",
    component: () => import("../components/Research.vue"),
  },
  {
    path: "/Labs",
    name: "Labs",
    component: () => import("../components/Labs.vue"),
  },
  {
    path: "/Leader/:type/:id",
    name: "Leader-with-id-And-Type",
    component: () => import("../components/Person.vue"),
  },
  {
    path: "/Leader/:type/:id/:BrnId/:parentID",
    name: "Leader-with-id-And-brnId-And-type",
    component: () => import("../components/Dept-Leader.vue"),
  },
  {
    path: "/Page/:type/:id",
    name: "Page-with-id-And-Type",
    component: () => import("../components/Page.vue"),
  },
  {
    path: "/Service/:id/:type",
    name: "Service",
    component: () => import("../components/Servicess.vue"),
  },
  {
    path: "/ComputerLabs/:id",
    name: "ComputerLabs",
    component: () => import("../components/ComputerLabs.vue"),
  },
  {
    path: "/Centers/:id",
    name: "Centers-with-id",
    component: () => import("../components/Centers.vue"),
  },
  {
    path: "/IssueArtical/:type/:BrnId/:id",
    name: "IssueArtical-with-id",
    component: () => import("../components/IssueArtical.vue"),
  },
  {
    path: "/Videos/:type",
    name: "Videos",
    component: () => import("../components/Videos.vue"),
  },
  {
    path: "/changeLang/:lang",
    name: "changeLang",
    component: () => import("../components/changeLang.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
